<template>
        <v-container fluid>
            <v-overlay :value="overlay" z-index="1001">
        <v-progress-circular indeterminate size="64" />
        </v-overlay>
        <vpd-confirm-dialog name="banklist-confirm-dialog"
            headline="Figyelem"
            subtitle="A számlacsatolás első lépéseként bankja az alábbi adatok megadását kéri. Ezt követően majd átirányítjuk a bank oldalára, ahol a bank elvégzi a további beazonosítást."
            :dialog="dialog"
            :bankId="selectedBankId"
            :schema="schema"
            event="redirect-to-bank"
            v-on:redirect-to-bank="redirectToSelectedBankUrl"
            v-on:close-modal="cancelDialog"
        >
        </vpd-confirm-dialog>

        <div class="vpd-bank-select-container">
            <div class="text-medium mb-8">
                Válassza ki melyik bankszámláját szeretné megosztani
            </div>
            <div class="d-flex">
                <v-select
                    v-model="selectedBank"
                    :items="config.aspspProviders"
                    :item-text="'meta.description'"
                    :item-disabled="option => option.id === null"
                    label="Válassza ki a számlavezető bankját"
                    outlined
                    return-object
                >
                <template v-slot:item="{ item }">
                    <div v-if="item.id">
                        <img class="mr-2" :src="item.meta.image" :width="'40px'">{{ item.meta.description }}
                    </div>
                    <div v-else>{{ item.meta.description }}</div>
                </template>
            </v-select>
            </div>
            <div class="d-flex justify-center w-max">
                <v-btn :disabled="!selectedBank.id" class="primary huge full-width" @click.stop="selectBank(selectedBank)">ÚJ BANKSZÁMLA CSATLAKOZTATÁSA</v-btn>
            </div>
        </div>
    </v-container>
</template>
<script>
    import "./_vpd-banklist-select.scss";
    import bankListService from "@/services/banklist-service";
    import VpdConfirmDialog from "@/components/vpd-confirm-dialog/vpd-confirm-dialog";
    import bankRequirementsService from "@/services/bank-requirements-service";

    export default {
    components: {VpdConfirmDialog},
    props: ["config"],
    data() {
        return {
        dialog: false,
        active: false,
        overlay: false,
        apiName: "RestApi",
        path: null,
        selectedBank: this.config.aspspProviders[0],
        selectedBankId: null,
        schema: null,
        };
    },
    methods: {
        async selectBank(selectedBank) {
            this.selectedBankId = selectedBank.id
            this.setLoading(true);
            bankRequirementsService.get(selectedBank.id).then((resp) => {
                if (!resp.items) {
                    this.redirectToSelectedBankUrl()
                    return;
                }
                this.schema = resp.items;
                this.setLoading(false);

                this.dialog = true;
            });
            this.path = selectedBank.auth.redirect;
        },
        cancelDialog() {
        this.dialog = false;
        this.path = null;
        },
        async redirectToSelectedBankUrl(formValues=null) {
            const requestBodyObject = {};
            if (!formValues) {
                this.redirect(requestBodyObject)
                this.setLoading(false);
            }

            const keys = Object.keys(formValues);
            
            keys.forEach(key => {
                requestBodyObject[key.toLocaleLowerCase()] = formValues[key]
            })

            this.dialog = false;
            this.setLoading(true);
            this.redirect(requestBodyObject)
            },
            setLoading: function (value) {
            this.overlay = value;
        },
        async redirect(requestBodyObject) {
            this.setLoading(true);
            await bankListService.select(this.path, requestBodyObject).then((resp) => {
            if (resp !== undefined) {
                window.location = resp.url;
            }
                this.setLoading(false);
            });
        }
    },
    };
</script>