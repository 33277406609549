<template>
  <v-container class="vpd-sub-content" fluid>
    <v-overlay :value="overlay" z-index="1000">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <div v-if="accounts[0]">
      <div class="d-flex justify-center">
        <div class="vpd-account-summary__text mb-2 mt-8">
          <span class="me-1">Utolsó frissítés időpontja: </span><span>{{this.lastSynchronizationDate}}</span>
        </div>
      </div>
      <v-divider  />
      <vpd-account-panel :accounts="accounts" :config="config" @refreshAccounts="refreshAccounts" @setSelectedAccounts="setSelectedAccounts($event)">
      </vpd-account-panel>
      <div v-if="hasSelectedAccount">
        <div class="d-flex flex-column mt-8">
          <span class="font-weight-bold font-md">A megosztás egyszeri alkalomra szól, ezt követően a LeanPay nem fér hozzá az adataihoz!
          </span>
          <div class="d-flex justifiy-start text-left mb-4 font-md">
            <ul>
              <li v-for="item of SHARED_DATA" :key="item">{{ item }}</li>
            </ul>
          </div>
          <span class="font-md">
            A kiválasztott bankszámlák megosztása:
          </span>
          <div class="d-flex justify-center mb-8">
            <v-btn class="primary huge fix-width" @click.stop="connectService(config)">Megosztás</v-btn>
          </div>
        </div>
        <v-divider v-if="!hasSelectedAccount"/>
      </div>
      <div v-if="!hasSelectedAccount" class="d-flex justify-center align-center">
        <vpd-banklist-select :config="config" />
      </div>
    </div>

    <div v-else class="py-5">
      <div class="d-flex justify-center align-center">
        <vpd-banklist-select :config="config" />
      </div>
    </div>
  </v-container>
</template>

<script>
import VpdAccountPanel from "@/components/vpd-account-panel/vpd-account-panel";
import VpdBanklistSelect from "@/components/vpd-banklist-select/vpd-banklist-select";
import accountsService from "@/services/accounts-service";
import servicesService from "@/services/services-service";
import VpdEventBus from "../modules/vpd-event-bus";

export default {
  data() {
    return {
      loadingAccounts: false,
      accounts: [],
      accountNumbers: [],
      overlay: false,
      lastSynchronizationDate: '',
      hasSelectedAccount: false,
    };
  },
  name: "vpd-home",
  components: {
    VpdAccountPanel,
    VpdBanklistSelect
  },
  props: ["config"],
  methods: {
    loadAccountsData: function (data) {
      this.accounts = data.items.filter(account => account.isValid.toLowerCase() === "true" || account.deletedAt);
      // this.accounts = [];
      this.lastSynchronizationDate = data.lastSynchronizationDate.substring(0,16);
      this.$store.commit("setAccountsCount", this.accounts.length);
    },
    setLoading: function (value) {
      this.overlay = value;
      this.loadingAccounts = value;
    },
    refreshAccounts: async function () {
      const accountsData = await accountsService.fetchAccounts();
      if (accountsData) this.loadAccountsData(accountsData);
    },
    setSelectedAccounts(accounts) {
      this.accounts = accounts;
      this.hasSelectedAccount = this.accounts.filter(account => account.isSelected).length > 0 ? true : false;
    },
    async connectService(config) {
      const leanPay = config.services[0];
      const serviceId = leanPay.id;
      const body = {}
      const accountList = this.accounts.filter(account => account.isSelected).map(account => {
        return account.id;
      });

      body["accountList"] = accountList;
      body["entityId"] = this.entityId;

      this.dialog = await servicesService.addServiceToAccounts(
        "/" + serviceId + "/accounts",
        body
      ).then(() => {
        this.$router.push({ name: "confirmation", params: {isSuccess: true} }).catch(() => {});
      }).catch(() => {
        this.$router.push({ name: "confirmation", params: {isSuccess: false} }).catch(() => {});
      });
    }
  },
  async created() {
    this.SHARED_DATA = [
    "Bank neve",
    "Bankszámlaszáma",
    "Tranzakciótörténet",
    "Számlaegyenlege",
    "Az Ön neve",
    "Az Ön e-mail címe",
    ];

    VpdEventBus.$on("refresh-account", this.refreshAccounts);
    this.$store.commit("setAccountsCount", 0);
    this.setLoading(true);
    await this.refreshAccounts().catch(_ => {
      this.setLoading(false);
    });
    this.setLoading(false);
  },
  destroyed() {
    VpdEventBus.$off("refresh-account", this.refreshAccounts);
  },
};
</script>
