const config = {
  identityPoolId: "eu-central-1:775ba88d-ecf7-47db-af53-2dfeb660f4b0",
  region: "eu-central-1",
  userPoolId: "eu-central-1_K2EsLBXIF",
  userPoolWebClientId: "3scgcuv4irfc35a3h6dekd4ov4",

  oauth: {
    //scope: ["email", "profile", "openid"],
    responseType: "code", // or "token", note that REFRESH token will only be generated when the responseType is code
    domain: "nyb-portal-prod.auth.eu-central-1.amazoncognito.com",
    redirectSignIn: "https://leanpay.portal.bankszamlakivonat.hu/",
    redirectSignOut: "https://leanpay.portal.bankszamlakivonat.hu/"
  },

  API: {
    endpoints: [
      {
        name: "RestApi",
        endpoint: "https://rest.bankszamlakivonat.hu",
        custom_header: async () => {
          return { Authorization: "token" };
        }
      },
        {
        name: "SocketApi",
        endpoint: "wss://socket.bankszamlakivonat.hu"
      }
    ]
  }
};

export default config;
