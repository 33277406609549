<template>
  <div>
    <div class="panel-title d-flex justify-center mb-8">
      Csatolt bankszámlák
    </div>
    <v-container class="vpd-content mt-2-5" fluid>
      <v-overlay :value="overlay" z-index="1000">
        <v-progress-circular indeterminate size="64" />
      </v-overlay>
        <div class="vpd-accounts">
          <router-view></router-view>
        </div>
    </v-container>
    <div class="d-flex justify-center mt-8">
      <a @click="redirectProvider(redirectUrl)">
        Vissza a Leanpay oldalára
      </a>
    </div>
  </div>
</template>

<script>
import "./_Home.scss";
import leanPayService from "@/services/leanpay-service";
import { getLeanpayRedirectUrl } from "@/modules/helpers";

export default {
  data() {
    return {
      overlay: false,
      redirectUrl: ""
    };
  },
  methods: {
    redirectProvider(redirectUrl) {
      window.location.href = redirectUrl;
    },
  },
  name: "vpd-home",
  async created() {
    const leanPayData = await leanPayService.getRedirectUrl();
    this.redirectUrl = leanPayData?.redirectUrl ?? "";
    if (this.redirectUrl !== "") {
      this.redirectUrl = getLeanpayRedirectUrl(this.redirectUrl, leanPayData);
    }
  }
};
</script>
