<template>
  <div>
    <div class="panel-title d-flex justify-center mb-8">
      Regisztráció
    </div>
    <v-card class="vpd-panel vpd-registration-panel">
      <v-alert
        v-model="alert"
        :type="alertType"
        close-text="Bezárás"
        dismissible
        @click="alert = false"
        >{{ alertMessage }}
      </v-alert>
      <v-form ref="form" v-model="valid" lazy-validation>
        <div justify="center">
          <v-col align="center" class="pt-1 pb-2">
            <v-text-field
              label="Név"
              v-model="username"
              :rules="nameRules"
              outlined
              dense
              height="50"
            >
            </v-text-field>
          </v-col>
        </div>
        <div justify="center">
          <v-col align="center" class="pt-1 pb-2">
            <v-text-field
              :disabled="emailDisabled"
              label="Email cím"
              v-model="email"
              :rules="emailRules"
              outlined
              dense
              height="50"
            >
            </v-text-field>
          </v-col>
        </div>
        <div justify="center">
          <v-col align="center" class="pt-1 pb-2">
            <v-text-field
              label="Jelszó"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              hint="Min.8 karakter"
              @click:append="showPassword = !showPassword"
              v-model="password"
              :rules="passwordRules"
              outlined
              dense
              height="50"
            >
            </v-text-field>
          </v-col>
        </div>
        <div justify="center">
          <v-col align="center" class="pt-1 pb-2">
            <v-text-field
              label="Jelszó újra"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              :rules="matchRules"
              outlined
              dense
              height="50"
              ref="passcheck"
            >
            </v-text-field>
          </v-col>
        </div>
        <div justify="center" class="pa-0">
          <v-col align="center" class="pa-0 col-dense">
            <v-container fluid class="d-flex flex-column align-center py-0">
              <v-checkbox
                v-model="privacyAccepted"
                class="mt-3 pl-3"
                :rules="acceptRule"
              >
                <span slot="label" class="pl-4"
                  ><router-link :to="{ name: 'privacy-policy' }">
                    Az Adatvédelmi szabályzatot megértettem és
                    elfogadom</router-link
                  ></span
                >
              </v-checkbox>

              <v-checkbox
                v-model="termsAccepted"
                class="mt-4 mb-2 pl-3"
                :rules="acceptRule"
              >
                <span slot="label" class="pl-4"
                  ><router-link :to="{ name: 'services-terms-and-conditions' }">
                    Az Általános szerződési feltételeket megértettem és elfogadom</router-link
                  ></span
                >
              </v-checkbox>
            </v-container>
          </v-col>
        </div>
        <div class="mt-8" justify="center">
          <v-col align="center">
            <v-btn
              class="primary huge fix-width"
              type="submit"
              @click.prevent="submit()"
              >REGISZTRÁCIÓ
            </v-btn>
          </v-col>
        </div>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import "./_vpd-registration-panel.scss";

export default {
  name: "vpd-registration-panel",
  props: ["config"],
  components: {},
  computed: {
    matchRules() {
      const context = this;
      return [
        function (v) {
          return (v && v === context.password) || "A jelszók nem egyeznek";
        },
      ];
    },
  },
  data: () => ({
    alert: false,
    alertMessage: "",
    alertType: "error",
    valid: false,
    password: "",
    username: "",
    email: "",
    emailDisabled: false,
    termsAccepted: false,
    privacyAccepted: false,
    showPassword: false,
    emailRules: [
      (v) => !!v || "Az email cím kötelező",
      (v) => /.+@.+/.test(v) || "Érvénytelen email",
    ],
    passwordRules: [
      (v) => !!v || "Hiányzó jelszó",
      (v) => (v && v.length >= 8) || "A jelszó min. 8 karakter",
      (v) =>
        /[a-zaáeéiíoóöőuúüű]/.test(v) ||
        "A jelszónak min. 1 kisbetűt kell tartalmaznia",
      (v) =>
        /[A-ZAÁEÉIÍOÓÖŐUÚÜŰ]/.test(v) ||
        "A jelszónak min. 1 nagybetűt kell tartalmaznia",
      (v) => /[0-9]+/.test(v) || "A jelszónak min. 1 számot kell tartalmaznia",
    ],
    nameRules: [
      (v) => !!v || "A név megadása kötelező",
      (v) =>
        /^[a-zaáeéiíoóöőuúüűA-ZAÁEÉIÍOÓÖŐUÚÜŰ ]+$/.test(v) ||
        "A név nem tartalmazhat speciális karaktert.",
    ],
    acceptRule: [(v) => v === true || "A szabályzat elfogadása kötelező"],
  }),
  methods: {
    submit() {
      //TODO remove this.$refs
      if (this.$refs.form.validate()) {
        this.$emit("submit", this.username, this.password, this.email);
      }
    },
    showAlert(message, type) {
      this.alertMessage = message;
      this.alertType = type;
      this.alert = true;
    }
  },
  async created() {
    if (this.$route.query.email) {
      this.email = this.$route.query.email;
      this.emailDisabled = true;
    }
  }
};
</script>
