<template>
  <v-card class="vpd-panel">
    <v-alert
      v-if="alertText && alertText.length>0"
      v-model="alertText"
      :type="alertType"
      close-text="Bezárás"
      dismissible
      @click="alert = false"
      >{{ alertText }}
    </v-alert>
    <vpd-card-title v-if="titleText" :title="titleText"></vpd-card-title>
    <v-row v-if="bodyText" justify="center">
      <v-col align="center" class="subtitle-1">
        {{ bodyText }}
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col align="center">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="buttonText">
      <v-btn
        :class="buttonColor"
        class="fix-width"
        @click="navigate(buttonRoute)"
      >
        {{ buttonText }}
      </v-btn>
    </v-row>
  </v-card>
</template>

<script>
import VpdCardTitle from "@/components/vpd-card-title/vpd-card-title";
export default {
  name: "vpd-notification-panel",
  components: { VpdCardTitle },
  props: {
    titleText : {
      type: String
    },
    bodyText : {
      type: String
    },
    buttonText : {
      type: String
    },
    buttonColor : {
      type: String
    }, 
    buttonRoute : {
      type: String
    },
    alertText: {
      type: String
    },
    alertType : {
      type: String
    },
  },
  methods: {
    navigate(path) {
      return this.$router.push(path).catch(() => {});
    },
  },
};
</script>
