<template>
  <v-overlay :value="overlay" z-index="1000">
    <v-progress-circular indeterminate size="64" />
  </v-overlay>
</template>

<script>
import { handleOAuth } from "../modules/bank-oauth-handler";

export default {
  data: () => ({
    overlay: false,
  }),
  name: "vpd-callback",
  async created() {
    this.overlay = true;
    const redirectUrl = await handleOAuth(this.$route.meta.props.to);
    await this.$router.push({ path: redirectUrl }).catch(() => {});
  },
};
</script>
