<template>
  <v-container class="vpd-account-panels v-sub-content">
    <v-overlay :value="overlay" z-index="1001">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <vpd-confirm-dialog
      headline="Valóban meg szeretné szüntetni bankszámlája csatolását?"
      subtitle="Ha folytatja a megszüntetést, ezt követően bankszámla és tranzakciós adatai nem fognak frissülni a bankszámlakivonat.hu rendszerében. Ez hatással lehet az Ön által korábban beállított számlatörténet megosztásokra is. Biztosan folytatja a megszüntetést?"
      :dialog="consentCancelDialog"
      event="remove-bank-account-from-account"
      v-on:remove-bank-account-from-account="removeBankAccountFromAccount"
      v-on:close-modal="consentCancelDialog = false"
    ></vpd-confirm-dialog>
    <vpd-confirm-dialog
      headline="Valóban meg szeretné újítani a megosztást?"
      subtitle="A megosztás megújításának első lépéseként bankja az alábbi adatok megadását kéri. Ezt követően majd átirányítjuk a bank oldalára, ahol a bank elvégzi a további beazonosítást."
      :dialog="consentRenewalDialog"
      :bankId="selectedBank"
      :schema="schema"
      event="renew-consent"
      v-on:renew-consent="renewConsent"
      v-on:close-modal="consentRenewalDialog = false"
    ></vpd-confirm-dialog>
    <template>
      <v-expansion-panels accordion>
        <v-expansion-panel
          v-for="account in accountsToSelect"
          :key="account.id"
          class="vpd-account-panel"
        >
        <div class="d-flex">
          <v-expansion-panel-header class="vpd-expand-header pa-0">
            <div class="vpd-account-panel-row">
              <div
                class="d-flex space-between align-center"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <span class="d-flex" v-on="on">
                      <v-checkbox :v-model="account.isSelected"
                        v-on:change="setSelectedAccounts(account)"
                        :disabled="account.deletedAt != null || account.expired">
                      </v-checkbox>
                    </span>
                  </template>
                  <span v-if="account.deletedAt != null || account.expired" class="d-flex">Csak aktív számlát lehet kiválasztani!</span>
                  <span v-else class="d-flex">Számla kiválasztása.</span>
                </v-tooltip>
                <img v-if="account.deletedAt || account.expired" style="filter: grayscale(100%);" :src="getBankMiniImgUrl(account.bank)" />
                <img v-else :src="getBankMiniImgUrl(account.bank)" />
                <div class="ps-3">
                  <div
                    v-if="account.name"
                    class="subtitle-2 font-weight-bold py-0"
                  >
                    {{ account.name }}
                  </div>
                  <div 
                    class="caption"
                    v-if="!account.expired && !account.deletedAt"
                    key="transactions-expired-state"
                  >
                    A tranzakciók lekérdezve
                    <span
                      v-if="account.synchronizedFrom"
                      class="font-weight-bold"
                      >{{ diffInDays(account.synchronizedFrom) }}</span
                    >
                    napra visszamenőleg
                  </div>
                  <div v-if="account.expired && !account.deletedAt" class="caption" key="transactions-expired-state">
                    A tranzakciók
                    <span v-if="account.expireAt" class="font-weight-bold">{{
                      since(account.expireAt)
                    }}</span>
                    nem kerülnek lekérdezésre
                  </div>
                  <div v-if="account.deletedAt" class="caption" key="transactions-expired-state">
                    Lejárt {{since(account.deletedAt)}}
                  </div>
                </div>
              </div>
            </div>
            <template v-slot:actions>
              <vpd-chevron-down></vpd-chevron-down>
            </template>
          </v-expansion-panel-header>
          </div>
          <v-expansion-panel-content>
            <v-container class="vpd-account-panel-placeholder">
              <div class="vpd-account-panel-col">
                <span class="subtitle-2 font-weight-bold">
                  Számlaszám:
                </span>
                <span v-if="account.bban" class="subtitle-2 vpd-account-panel-span">
                {{ account.bban}} - {{account.currency }}</span>
              </div>
              <div class="vpd-account-panel-col">
                <span class="subtitle-2 font-weight-bold">Csatlakoztatva: </span>
                <span class="subtitle-2 vpd-account-panel-span">
                  {{ account.attachedAt | moment()}}  {{ "(" + since(account.attachedAt) + ")" }}</span>
              </div>
              <div v-if="!account.deletedAt && !account.expired" class="vpd-account-panel__status1 vpd-account-panel-col">
                <span class="subtitle-2 font-weight-bold">Lejár: </span>
                <span class="subtitle-2 vpd-account-panel-span">
                  {{ account.expireAt | moment() }} {{ "(" + since(account.expireAt) + ")" }}</span>
              </div>
                <div v-else class="vpd-account-panel__status1 vpd-account-panel-col">
                <span class="subtitle-2 font-weight-bold">Lejárt: </span>
                <span class="subtitle-2 vpd-account-panel-span">
                  {{ (account.deletedAt ? account.deletedAt : account.expireAt) | moment() }}</span>
              </div>
              <div class="vpd-account-panel__status2 mt-2">
                <div>
                  <span class="d-flex align-center">
                    <a class="mr-2" v-if="!account.deletedAt && account.expired "
                      href="#"
                      @click.stop="openConsentAttachDialog(account, true)">
                      Megújítás
                      </a>
                    <a v-else
                      href="#"
                      @click.stop="openConsentAttachDialog(account, false)">
                      Csatolás
                      </a>
                  </span>   
                </div>
              </div>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-divider />
    </template>
  </v-container>
</template>

<script>
import "./_vpd-account-panel.scss";
import moment from "moment";
import {
  getFormattedAccountNumber,
  getFormattedAmountValue,
  getBankImgUrl,
  getBankMiniImgUrl,
  getServiceImgUrl,
} from "@/modules/helpers";
import VpdConfirmDialog from "@/components/vpd-confirm-dialog/vpd-confirm-dialog";
import VpdChevronDown from "@/components/icons/VpdChevronDown";
import bankAccountConsentService from "@/services/bank-account-consent-service";
import bankRequirementsService from "../../services/bank-requirements-service";
import bankListService from "@/services/banklist-service";

export default {
  name: "vpd-account-panel",
  props: {
    accounts: {
      type: Array,
      required: true,
    },
    config: Object 
  },
  components: { VpdChevronDown, VpdConfirmDialog },
  data: function () {
    return {
      dialog: false,
      consentCancelDialog: false,
      consentRenewalDialog: false,
      isRenewal: false,
      overlay: false,
      apiName: "RestApi",
      path: null,
      redirectUrl: null,
      accountId: null,
      selectedBank: null,
      schema: null,
      accountsToSelect: null
    };
  },
  methods: {
    openDialogForSelectedRow(serviceId, accountId, serviceIndex, accountIndex) {
      this.dialog = true;
      //TODO check binding options
      this.selectedServiceId = serviceId;
      this.selectedAccountId = accountId;
      this.selectedServiceIndex = serviceIndex;
      this.selectedAccountIndex = accountIndex;
    },
    openConsentCancelDialog(account) {
      this.accountId = account.id;
      this.consentCancelDialog = true;
    },
    setSelectedAccounts(account) {
      account.isSelected = !account.isSelected;
      this.$emit("setSelectedAccounts", this.accountsToSelect);
    },
    async openConsentAttachDialog(account, isUnderRenewal) {
      this.isRenewal = isUnderRenewal;
      this.selectedBank = account.bank;
      this.accountId = account.id;
      this.setLoading(true);
      this.redirectUrl = this.config.aspspProviders.filter(provider => provider.id == this.selectedBank)[0].auth.redirect;
      bankRequirementsService.get(this.selectedBank).then((resp) => {
          this.schema = resp.items;
          this.setLoading(false);
          this.consentRenewalDialog = true;
      });
    },
    async removeBankAccountFromAccount() {
      let gotResponse = false;
      while(!gotResponse) {
        await bankAccountConsentService.delete(this.accountId).then(resp => {
          if (resp != undefined) {
            gotResponse = true;
          }
        });
      }
      this.$emit("refreshAccounts");
      this.consentCancelDialog = false;
    },
    async renewConsent(formValues) {
      const keys = Object.keys(formValues);
      const requestBodyObject = {};
      keys.forEach(key => {
        requestBodyObject[key.toLocaleLowerCase()] = formValues[key]
      })

      this.consentRenewalDialog = false;
      this.setLoading(true);
      let gotRedirectUrl = false;
      let numOfAttempts = 3;
      while(!gotRedirectUrl) {
        if (this.isRenewal) {
          await bankAccountConsentService.renew(this.accountId, requestBodyObject).then(path => {
            if (path != undefined) {
              gotRedirectUrl = true;
              window.location = path;
            }
            this.setLoading(false);  
          });
        }
        if (!this.isRenewal) {
          await bankListService.select(this.redirectUrl, requestBodyObject).then((resp) => {
            if (resp !== undefined) {
              gotRedirectUrl = true;
              window.location = resp.url;
            }
            this.setLoading(false);  
          });
        }
          numOfAttempts -= 1;
          if (numOfAttempts == 0) {
            break;
          }
        
      }
      this.setLoading(false)
    },
    diffInDays(date) {
      var now = moment();
      return now.diff(date, "days");
    },
    since(date) {
      moment.locale("hu");
      return moment(Date.parse(date)).fromNow();
    },
    getBankImgUrl(bank) {
      return getBankImgUrl(bank);
    },
    getBankMiniImgUrl(bank) {
      return getBankMiniImgUrl(bank);
    },
    getServiceImgUrl(service) {
      return getServiceImgUrl(service.id);
    },
    getNiceValue(value, currency) {
      return getFormattedAmountValue(value, currency);
    },
    getFormattedAccountNumber(accountNumber) {
      return getFormattedAccountNumber(accountNumber);
    },
    moment: function () {
      return moment();
    },
    addDays(date, day) {
      return moment(date, "YYYY-MM-DD").add(day, "days");
    },
    setLoading: function (value) {
      this.overlay = value;
    },
  },
  async created() {
    this.accountsToSelect = this.accounts.map(account => {
      return {...account, isSelected: false}
    })
  },
  filters: {
    moment: function (date) {
      return moment(date).format("YYYY-MM-DD HH:mm");
    },
  },
};
</script>
