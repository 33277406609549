// src/utils/auth.js
import { Auth } from "aws-amplify";
import { AmplifyEventBus } from "aws-amplify-vue";
import cookieService from "../services/cookie-service";

Auth.configure({cookieStorage: {
  domain: "bankszamlakivonat.hu",
  secure: false
}})

function getUser() {
  return Auth.currentAuthenticatedUser()
    .then((user) => {
      if (user && user.signInUserSession) {
        return user;
      } else {
        return null;
      }
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
}

async function signUp(username, password, email) {
  return Auth.signUp({
    username: email,
    password: password,
    attributes: {
      name: username,
    },
    clientMetadata:{
      domain:"leanpay",
    },
  })
    .then((data) => {
      AmplifyEventBus.$emit("localUser", data.user);
      return signIn(email, password);
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
}

async function confirmSignUp(username, code) {
  return Auth.confirmSignUp(username, code)
    .then((data) => {
      AmplifyEventBus.$emit("authState", "signIn");
      return { code: "success", data }; // 'success'
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
}

async function confirmNewpass(username, code, password) {
  return Auth.forgotPasswordSubmit(username, code, password)
    .then((data) => {
      AmplifyEventBus.$emit("authState", "signIn");
      return { code: "success", data }; // 'success'
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
}

async function resendSignUp(username) {
  return Auth.resendSignUp(username)
    .then(() => {
      return { code: "success" };
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
}

async function forgotPassword(username) {
  return Auth.forgotPassword(username)
    .then(() => {
      //AmplifyEventBus.$emit("authState", "forgotPassword");
      return { code: "success" };
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
}

async function signIn(username, password) {
  cookieService.clearAllCookies()
  await Auth.signOut({ global: true });
  try {
    const user = await Auth.signIn(username, password);
    if (user) {
      cookieService.setCookie('source', 'leanpay', 1);
      AmplifyEventBus.$emit("authState", "signedIn");
      return { code: "success", user };
    }
  } catch (err) {
    if (err.code === "UserNotConfirmedException") {
      // The error happens if the user didn't finish the confirmation step when signing up
      // In this case you need to resend the code and confirm the user
      // About how to resend the code and confirm the user, please check the signUp part
    } else if (err.code === "PasswordResetRequiredException") {
      // The error happens when the password is reset in the Cognito console
      // In this case you need to call forgotPassword to reset the password
      // Please check the Forgot Password part.
    } else if (err.code === "NotAuthorizedException") {
      // The error happens when the incorrect password is provided
    } else if (err.code === "UserNotFoundException") {
      // The error happens when the supplied username/email does not exist in the Cognito user pool
    } else {
      console.log(err);
    }
    return err;
  }
}

async function signOut() {
  return Auth.signOut({ global: true })
    .then((data) => {
      AmplifyEventBus.$emit("authState", "signedOut");
      return data;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
}

export {
  getUser,
  signUp,
  confirmSignUp,
  resendSignUp,
  signIn,
  signOut,
  forgotPassword,
  confirmNewpass,
};
