<template>
  <div>
    <header class="vpd-toolbar">
      <nav>
        <ul
          class="vpd-navigation d-flex d-row justify-center align-center"
        >
          <li>
            <v-img
              @click="routeToHome"
              class="vpd-toolbar__logo"
              :src="require('../../assets/logo-transparent.svg')"
              height="51"
              contain
              alt="Bankszamlakivonat.hu"
            />
          </li>
        </ul>
      </nav>
    </header>
  </div>
</template>

<script>
import "./_vpd-toolbar.scss";

export default {
  data() {
    return {
      userState: 0,
    };
  },
  methods: {
    routeToHome() {
      return this.$router.push("/").catch(() => {});
    },
  },
  created() {
    this.$store.subscribe((mutation, _) => {
      if (mutation.type === "general/userState") {
        this.userState = +(mutation.payload === "signedIn");
      }
    });
  },
};
</script>
