const blue = Object.freeze({
  pastel: "#3873BB",
  light: "#14BADE",
  secondary: "#38AACE",
  new: "#1B8FE2",
  extra: "#F6F8FA",
  facebook: "#2F80ED",
});

const green = Object.freeze({
  base: "#A7CC15",
  success: "#84A814",
  otp: "#A7D81E",
});

const yellow = Object.freeze({
  base: "#EFBE12",
  pastel: "#FDE47F",
});

const grey = Object.freeze({
  base: "#333333",
  dark: "#42505C",
  pastel: "#727E8C",
  light: "#E0E4E6",
});

const purple = Object.freeze({
  base: "#BB6BD9",
});

const red = Object.freeze({
  base: "#cd5252",
  google: "#EB5757",
});

const magenta = Object.freeze({
  base: "#E1337C",
});

const peach = Object.freeze({
  base: "#FF6A71",
});


const colors = Object.freeze({
  red: red,
  blue: blue,
  green: green,
  yellow: yellow,
  grey: grey,
  purple: purple,
  magenta: magenta,
  peach: peach,
});

export default {
  options: {
    customProperties: true,
  },
  themes: {
    light: {
      anchor: blue.light,
      primary: colors.peach.base,
      secondary: "#14BADE", //blue.light,
      accent: blue.secondary,
      error: colors.red,
      info: colors.blue.pastel,
      success: colors.green.success,
      warning: "#FB8C00",

      red: colors.red.base,
      grey: colors.grey.base,
      yellow: colors.yellow.base,
      green: colors.green.base,
      purple: colors.purple.base,

      "blue-pastel": {
        base: colors.blue.pastel,
        lighten1: colors.blue.new,
        lighten2: colors.blue.secondary,
        lighten3: colors.blue.light,
        lighten4: colors.blue.extra,
      },
      "yellow-pastel": {
        base: colors.yellow.pastel,
      },
      "green-otp": {
        base: colors.green.otp,
      },
      "grey-dark": {
        base: colors.grey.dark,
      },
      "grey-pastel": {
        base: colors.grey.pastel,
      },
      "grey-light": {
        base: colors.grey.light,
      },
      "blue-facebook": {
        base: colors.blue.facebook,
      },
      "red-google": {
        base: colors.red.google,
      },
    },
  },
};
