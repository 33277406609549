<template>
  <div>
    <div class="panel-title d-flex justify-center mb-8">
      Bejelentkezés
    </div>
    <v-card class="vpd-panel vpd-login-panel">
      <v-alert
        v-model="alert"
        :type="alertType"
        close-text="Bezárás"
        dismissible
        @click="alert = false"
        >{{ alertMessage }}
      </v-alert>
      <v-form v-model="valid" ref="form" autocomplete="on">
        <v-row justify="center">
          <v-col align="center" class="pt-1 pb-2">
            <v-text-field
              label="Email cím"
              v-model="email"
              :rules="emailRules"
              hide-details="auto"
              outlined
              dense
              height="50"
              autocomplete="email"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col align="center" class="pt-1 pb-2">
            <v-text-field
              label="Jelszó"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              v-model="password"
              hide-details="auto"
              hint="Min.8 karakter"
              @click:append="showPassword = !showPassword"
              :rules="passwordRules"
              outlined
              dense
              height="50"
              autocomplete="current-password"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col align="center" class="pt-1 pl-0 pr-0">
            <v-btn
              class="primary huge fix-width"
              type="submit"
              @click.prevent="submit"
              >BEJELENTKEZÉS
            </v-btn>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col align="center" class="pt-2 pb-2 pl-0 pr-0">
            <a @click="$emit('reset-pass')" class="vpd-login-panel__forgotten"
              >Elfelejtett jelszó?</a
            >
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import "./_vpd-login-panel.scss";

export default {
  data: () => ({
    alert: false,
    alertMessage: "",
    alertType: "error",
    valid: false,
    password: "",
    showPassword: false,
    email: "",
    emailRules: [
      (v) => !!v || "Az email cím kötelező",
      (v) => /.+@.+/.test(v) || "Érvénytelen email",
    ],
    passwordRules: [
      (v) => !!v || "Hiányzó jelszó",
      (v) => (v && v.length >= 8) || "A jelszó min. 8 karakter",
    ],
  }),
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.$emit("submit", this.email, this.password);
      }
    },
    showAlert(msg, type) {
      this.alertMessage = msg;
      this.alertType = type;
      this.alert = true;
    },
  },
};
</script>
