import Images from "@/assets/images.js";
const config = {
  aspspProviders: [
    {
      id: null,
      meta: {
        description: "Bank kiválasztása:",
      },
  },
  {
      id: "otp",
      auth: {
        availableAccounts: "/banks/otp/tokens/",
        redirect: "/banks/otp/redirecturl",
      },
      meta: {
        description: "OTP Bank",
        class: "bank-logo--otp",
        image: Images.logos.otp.original,
      },
  },
  {
      id: "magnet",
      auth: {
        availableAccounts: "/banks/magnet/tokens/",
        redirect: "/banks/magnet/redirecturl",
      },
      meta: {
        description: "Magnet Bank",
        class: "bank-logo--magnet",
        image: Images.logos.magnet.original,
      },
  },
  {
      id: "cib",
      auth: {
        availableAccounts: "/banks/cib/tokens/",
        redirect: "/banks/cib/redirecturl",
      },
      meta: {
        description: "Cib Bank",
        class: "bank-logo--cib",
        image: Images.logos.cib.original,
      },
  },
  {
      id: "unicredit",
      auth: {
        availableAccounts: "/banks/unicredit/tokens/",
        redirect: "/banks/unicredit/redirecturl",
      },
      meta: {
        description: "UniCredit Bank",
        class: "bank-logo--unicredit",
        image: Images.logos.unicredit.original,
      },
  },
  {
      id: "revolut",
      auth: {
        availableAccounts: "/banks/revolut/tokens/",
        redirect: "/banks/revolut/redirecturl",
      },
      meta: {
        description: "Revolut Bank",
        class: "bank-logo--revolut",
        image: Images.logos.revolut.original,
      },
    },
    {
      id: "raiffeisen",
      auth: {
        availableAccounts: "/banks/raiffeisen/tokens/",
        redirect: "/banks/raiffeisen/redirecturl",
      },
      meta: {
        description: "Raiffeisen Bank",
        class: "bank-logo--raiffeisen",
        image: Images.logos.raiffeisen.original,
      },
    },
    {
      id: "kh",
      auth: {
        availableAccounts: "/banks/kh/tokens/",
        redirect: "/banks/kh/redirecturl",
      },
      meta: {
        description: "K&H Bank",
        class: "bank-logo--kh",
        image: Images.logos.kh.original,
      },
    },
    {
      id: "erste",
      auth: {
        availableAccounts: "/banks/erste/tokens/",
        redirect: "/banks/erste/redirecturl",
      },
      meta: {
        description: "Erste Bank",
        class: "bank-logo--erste",
        image: Images.logos.erste.original,
      },
    },

    {
      id: "budapest",
      auth: {
        availableAccounts: "/banks/budapest/tokens/",
        redirect: "/banks/budapest/redirecturl",
      },
      meta: {
        class: "bank-logo--bb",
        description: "Budapest Bank",
        image: Images.logos.budapest.original,
      },
    },

    // {
    //   id: "granit",
    //   auth: {
    //     availableAccounts: "/banks/granit/tokens/",
    //     redirect: "banks/granit/redirecturl",
    //   },
    //   meta: {
    //     class: "bank-logo--granit",
    //     description: "Gránit Bank",
    //     image: Images.logos.granit.original,
    //   },
    // },
    {
      id: "mkb",
      auth: {
        availableAccounts: "/banks/mkb/tokens/",
        redirect: "banks/mkb/redirecturl",
      },
      meta: {
        class: "bank-logo--mkb",
        description: "MKB Bank",
        image: Images.logos.mkb.original,
      },
    },
    {
      id: "takarek",
      auth: {
        availableAccounts: "/banks/takarek/tokens/",
        redirect: "banks/takarek/redirecturl",
      },
      meta: {
        class: "bank-logo--takarek",
        description: "Takarek Bank",
        image: Images.logos.takarek.original,
      },
    },
  ],
  services: [
    {
      id: "3cb23ecf-a2c8-4193-b441-0f1945d9ccb1",
      name: "LeanPay",
    },
  ]
};

export default config;
