<template>
  <v-app>
    <vpd-toolbar/>
    <v-main>
      <v-container class="app-body" fluid>
        <notifications group="error" position="top right" style="margin-top:4rem; margin-right:1rem; margin-bottom: 1rem;">
          <template slot="body" slot-scope="props">
            <div style="background-color:#eb722d; border-radius: 8px; padding: 0.5rem;">
                <div style="display:flex; justify-content: space-evenly; gap: 0.5rem;">
                  <v-icon  large color="#ad3c3c">mdi-close</v-icon>
                  <div>
                    <div style="display:flex; justify-content: space-between;">                  
                      <span style="color:white; font-size: 15px; font-weight: 600; margin-bottom: 0px !important;">
                      {{props.item.title}}
                      </span>
                      <a class="close" @click="props.close">
                        <v-icon small color="white">mdi-close-circle-outline</v-icon>
                      </a>
                    </div>
                    <span style="color:white; font-size: 12px; display: block; float: left; width: 15rem !important;">
                      {{props.item.text}}
                    </span>
                  </div>
                </div>
            </div>
          </template>
        </notifications>
        <notifications group="success" position="top right" style="margin-top:4rem; margin-right:1rem; margin-bottom: 1rem;">
          <template slot="body" slot-scope="props">
            <div style="background-color:#47d78a; border-radius: 8px; padding: 0.5rem;">
                <div style="display:flex; justify-content: space-evenly; gap: 0.5rem;">
                  <v-icon  large color="#329f32">mdi-check</v-icon>
                  <div>
                    <div style="display:flex; justify-content: space-between;">                  
                      <span style="color:white; font-size: 15px; font-weight: 600; margin-bottom: 0px !important;">
                      {{props.item.title}}
                      </span>
                      <a class="close" @click="props.close">
                        <v-icon small color="white">mdi-close-circle-outline</v-icon>
                      </a>
                    </div>
                    <span style="color:white; font-size: 12px; display: block; float: left; width: 15rem !important;">
                      {{props.item.text}}
                    </span>
                  </div>
                </div>
            </div>
          </template>
        </notifications>
        <div class="content-body" no-gutters>
          <div class="leanpay-container">
              <img
                class="leanpay-logo"
                alt="Leanpay logo"
                srcset="
                  @/assets/LP_logo.png,
                "
              />
          </div>
          <v-col>
            <router-view></router-view>
          </v-col>
        </div>
        <vpd-cookie-banner @hideCookieBanner="hideCookieBanner" :showBanner="show"/>
        <v-row class="my-0" no-gutters>
          <v-col align="center py-0">
            <footer class="footer">
              <v-row no-gutters>
                <v-col class="text-center" cols="12">
                  <router-link :to="{ name: 'services-terms-and-conditions' }">Általános Szerződési Feltételek</router-link>
                  - <router-link :to="{ name: 'privacy-policy' }">Adatvédelmi Szabályzat</router-link>
                  - <router-link :to="{ name: 'cookie-policy' }">Cookie szabályzat</router-link>
                  - <span @click="showCookieBanner()" class="footer-menu-item">Cookie beállítások</span>
                  <br/>
                  {{ new Date().getFullYear() }} —
                  <strong>Nyíltbankolás Zrt.</strong>
                </v-col>
              </v-row>
            </footer>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import "./_App.scss";
import Vue from 'vue';
import VpdToolbar from "./components/vpd-toolbar/vpd-toolbar";
import VpdCookieBanner from "./components/vpd-cookie-banner/vpd-cookie-banner";
import SocketService from "./services/socket.service.js";
import VpdEventBus from "./modules/vpd-event-bus";

export default {
  name: "App",
  components: { VpdToolbar, VpdCookieBanner },
  data:() => ({
    show: Vue.cookie.get("accepted") ? false : true,
  }),
  props: ["config"],
  async created() {
    await SocketService.setupSocketConnection();
    if (SocketService.getSocket() != null)
      SocketService.getSocket().addEventListener("message", (event) => {
        let eventObj = JSON.parse(event.data);

        switch (eventObj.eventType) {
          case "account":
            this.$notify({
              group: 'success',
              title: 'Értesítés',
              text: 'Friss bankszámla adatok érkeztek!'
            });
            VpdEventBus.$emit("refresh-account");
            break;
          case "transaction":
            this.$notify({
              group: 'success',
              title: 'Értesítés',
              text: 'Friss bankszámla adatok érkeztek!'
            });
            VpdEventBus.$emit("refresh-transaction");
            break;
          default:
            console.log("Ismeretlen esemény típus");
        }
      });
  },
  methods: {
    hideCookieBanner() {
      this.show = false;
    },
    showCookieBanner() {
      this.show = true;
    } 
  }
};
</script>
