const images = {
  logos: {
     otp: {
      original: require("./logos/originals/otp-bank-logo.svg"),
      mini: require("./logos/mini/OTP.png"),
      thumbnail: require("./logos/originals/otp-bank-logo.svg"),
    },
    magnet: {
      original: require("./logos/originals/magnet-bank-logo.png"),
      mini: require("./logos/mini/Magnet.png"),
      thumbnail: require("./logos/originals/magnet-bank-logo.png"),
    },
    cib: {
      original: require("./logos/originals/cib-bank-logo.svg"),
      mini: require("./logos/mini/CIB.png"),
      thumbnail: require("./logos/originals/cib-bank-logo.svg"),
    },
    unicredit: {
      original: require("./logos/originals/unicredit-bank-logo.svg"),
      mini: require("./logos/mini/Unicredit.png"),
      thumbnail: require("./logos/originals/unicredit-bank-logo.svg"),
    },
    revolut: {
      original: require("./logos/originals/revolut-logo.svg"),
      mini: require("./logos/mini/Revolut.png"),
      thumbnail: require("./logos/originals/revolut-logo.svg"),
    },
    raiffeisen: {
      original: require("./logos/originals/raiffeisen-bank-logo.svg"),
      mini: require("./logos/mini/Raiffeisen.png"),
      thumbnail: require("./logos/originals/raiffeisen-bank-logo.svg"),
    },
    kh: {
      original: require("./logos/originals/kh-logo.png"),
      mini: require("./logos/mini/KH.png"),
      thumbnail: require("./logos/originals/kh-logo.png"),
    },
    budapest: {
      original: require("./logos/originals/budapest-bank-logo.svg"),
      mini: require("./logos/mini/BB.png"),
      thumbnail: require("./logos/originals/budapest-bank-logo.svg"),
    },
    erste: {
      original: require("./logos/originals/erste-bank-logo.svg"),
      mini: require("./logos/mini/Erste.png"),
      thumbnail: require("./logos/thumbnails/erstebank.svg"),
    },
    granit: {
      original: require("./logos/originals/granitbank.svg"),
      mini: require("./logos/mini/Granit.png"),
      thumbnail: require("./logos/originals/granitbank-simple.svg"),
    },
    takarek: {
      original: require("./logos/originals/takarekbank-zrt-logo.svg"),
      mini: require("./logos/mini/Takarek.png"),
      thumbnail: require("./logos/originals/takarekbank-zrt-logo.svg"),
    },
    mkb: {
      original: require("./logos/originals/mkb-bank-logo.svg"),
      mini: require("./logos/mini/MKB.png"),
      thumbnail: require("./logos/originals/mkb-bank-logo.svg"),
    },
    nobank: {
      original: require("./logos/originals/nobankicon.svg"),
      thumbnail: require("./logos/originals/nobankicon.svg"),
    }
  },
};

export default images;
