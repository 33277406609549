import axiosService from "@/modules/axios-service";

class ServicesService {
  path = "/services";
  constructor(path = undefined) {
    if (path) {
      this.path = path;
    }
  }

  async addServiceToAccounts(route, body) {
    return await axiosService.post(this.path + route, body, {
      ignoreGlobalCatch: true,
  },).then(() => {
      return true;
    });
  }
}

export default new ServicesService();
