import axiosService from "@/modules/axios-service";

class LeanPayService {
  path = "/leanpay";
  constructor(path = undefined) {
    if (path) {
      this.path = path;
    }
  }

  async getRedirectUrl() {
    const path = this.path + "/connections"
    return await axiosService.get(path);
  }
}

export default new LeanPayService();