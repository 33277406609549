import axiosService from "@/modules/axios-service";

async function handleOAuth(obj) {
  let finalObj;
  if (obj.hash != "") {
    finalObj = exportHash(obj.hash, finalObj);
  } else if (obj.query != {}) {
    finalObj = obj.query;
  } else {
    return;
  }

  if (finalObj) {
    console.log();
  } else {
    return;
  }

  let bank = obj.params.bank ? obj.params.bank : "mc";
  const path = "/banks/" + bank + "/callback";

  return await axiosService.post(path, { ...finalObj }).then((data) => {
    if (!data) {
      return "/confirmation";
    }
    return data.consentsRequired
      ? `/banks/${data.bank}?status=consentsrequired&tokenId=${data.tokenId}`
      : "/confirmation";
  });
}

function exportHash(hash) {
  const params = new URLSearchParams(hash.substring(1));
  let resultRaw = "{";
  for (var pair of params.entries()) {
    resultRaw += '"' + pair[0] + '":"' + pair[1] + '",';
  }
  resultRaw = resultRaw.slice(0, -1) + "}";

  return JSON.parse(resultRaw);
}

export { handleOAuth };
