<template>
    <div>
        <div class="confirmation panel-title d-flex justify-center mb-8">
            <span class="mr-1">A számlamegosztás</span> <span>{{(isSuccess ? "sikeres volt!" : "sikertelen volt!") }}</span>
        </div>
        <v-container class="mt-2-5" fluid>
            <v-overlay :value="overlay" z-index="1000">
                <v-progress-circular indeterminate size="64" />
            </v-overlay>
            <div class="vpd-confirmation">
                <div class="vpd-confirmation-action">
                    <v-btn v-if="!isSuccess"
                        class="primary huge fix-width"
                        @click.stop="redirectHome()"
                        >ÚJRA MEGOSZTÁS
                    </v-btn>
                    <v-btn
                    class="primary huge fix-width"
                    :class="[isSuccess ? 'success' : 'error']"
                    @click.stop="redirectProvider(redirectUrl)"
                    :disabled="isSuccess === null"
                    >VISSZA A SZOLGÁLTATÓHOZ
                    </v-btn>
                </div>
            </div>
        </v-container>
    </div>
</template>
  
<script>
import "./_Confirmation.scss";
import leanPayService from "@/services/leanpay-service";
import { getLeanpayRedirectUrl } from "@/modules/helpers";

export default {
    data() {
        return {
        overlay: false,
        isSuccess: null,
        redirectUrl: "",
        };
    },
    name: "vpd-confirmation",
    methods: {
        redirectHome() {
            this.$router.push({ name: 'accounts'}).catch(() => {});
        },
        redirectProvider(redirectUrl) {
            window.location.href = redirectUrl;
        },
    },
    async created() {
        if (this.$route.params.isSuccess !== undefined) {
            this.isSuccess = this.$route.params.isSuccess;
            const leanPayData = await leanPayService.getRedirectUrl();
            this.redirectUrl = leanPayData?.redirectUrl ?? "";
            if (this.redirectUrl !== "") {
                this.redirectUrl = getLeanpayRedirectUrl(this.redirectUrl, leanPayData);
            }
        }
    }
};
</script>