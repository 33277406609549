import images from "@/assets/images";

export const interpolate = function (params) {
  const names = Object.keys(params);
  const vals = Object.values(params);
  return new Function(...names, `return \`${this}\`;`)(...vals);
};

export const contextualRedirectUri = function (orgRedirectUri) {
  let url = new URL(window.location.origin);
  url.pathname = new URL(orgRedirectUri.split(",")[0]).pathname;

  return url.toString();
};

export const serialize = function (obj) {
  if (Array.isArray(obj)) {
    return JSON.stringify(obj.map((i) => serialize(i)));
  } else if (typeof obj === "object" && obj !== null) {
    return Object.keys(obj)
      .sort()
      .map((k) => `${k}:${serialize(obj[k])}`)
      .join("|");
  }

  return obj;
};

const getCurrencyShortFormat = (currency) => {
  const currency_symbols = {
    HUF: "Ft", //Forint
    USD: "$", // US Dollar
    EUR: "€", // Euro
    JPY: "¥", // Japanese Yen
  };

  if (currency_symbols[currency] !== undefined) {
    return currency_symbols[currency];
  } else return currency;
};

export const getFormattedAccountNumber = (accountNumber) => {
  let formattedAccountNumber =
    accountNumber.substring(0, 8) + "-" + accountNumber.substring(16, 8);
  if (accountNumber.length > 16) {
    formattedAccountNumber += "-" + accountNumber.substring(24, 16);
  }
  return formattedAccountNumber;
}

export const getFormattedAmountValue = (value, currency) => {
  if (value === null) {
    return "-";
  } else {
    return (
      Number(value).toLocaleString() + " " + getCurrencyShortFormat(currency)
    );
  }
};

export const getBankImgUrl = (bank) => {
  return images.logos[bank] && images.logos[bank].thumbnail
    ? images.logos[bank].thumbnail
    : images.logos.nobank.thumbnail;
};

export const getBankMiniImgUrl = (bank) => {
  return images.logos[bank].mini && images.logos[bank].thumbnail
    ? images.logos[bank].mini
    : images.logos.nobank.thumbnail;
};

export const getServiceImgUrl = (service) => {
  return images.logos[service] && images.logos[service].thumbnail
    ? images.logos[service].original
    : images.logos.noservice.thumbnail;
};

export const getBankOriginalImgUrl = (bank) => {
  return images.logos[bank] && images.logos[bank].original
    ? images.logos[bank].original
    : images.logos.nobank.original;
};

export const getImgUrl = (name) => {
  return images.icons[name] ? images.icons[name] : "";
};

export const getUnderConstructionImgUrl = (size) => {
  return images.underConstruction[size] ? images.underConstruction[size] : "";
}

export const getLeanpayRedirectUrl = (redirectUrl, leanPayData) => {
  const status = leanPayData?.status ?? "";
  const customerId = leanPayData?.customerId ?? "";
  const startChar = redirectUrl.includes("?") ? "&" : "?";
  const urlEnding = startChar + "status=" + status + "&customerId=" + customerId;
  const prefix = "";
  if(redirectUrl.includes("https://") || redirectUrl.includes("http://")){

  }
  else{
    prefix = "https://";
  }

  return prefix + redirectUrl + urlEnding
}