<template>
  <v-container class="vpd-content" fluid>
    <v-overlay :value="overlay" z-index="1000">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <vpd-login-panel
      @reset-pass="$router.push({ name: 'resetPass' })"
      @signup="$router.push({ name: 'registration' })"
      @submit="submitLogin"
      ref="loginPanel"
    >
    </vpd-login-panel>
  </v-container>
</template>

<script>
import VpdLoginPanel from "@/components/auth/vpd-login-panel/vpd-login-panel";
import { resendSignUp, signIn } from "../modules/auth";
import { camelCase } from "change-case";

const errorMessages = {
  UserNotConfirmedException: "A felhasználói fiók még nem lett aktiválva!",
  PasswordResetRequiredException:
    "A felhasználói jelszó bellítására van szükség!",
  NotAuthorizedException: "Érvénytelen felhasználónév vagy jelszó!",
  UserNotFoundException: "Hibás vagy nem  létező felhasználó azonosító!",
  success: "Sikeres belépés!",
};

const lockoutErrorMessage = "Zárolt felhasználó.";

export default {
  name: "vpd-login",
  props: ["config"],
  components: {
    VpdLoginPanel,
  },
  data: () => ({
    overlay: false,
  }),
  methods: {
    async submitLogin(email, password) {
      const panel = this.$refs.loginPanel;
      this.overlay = true;
      const status = await signIn(email, password);
      this.overlay = false;
      if (status.code === "success") {
        return true;
      }
      const handler = `${camelCase(status.code)}Handler`;
      if (typeof this[handler] === "function") {
        this[handler](panel, email);
      } else if (
        status.message === "PreAuthentication failed with error lockout."
      ) {
        panel.showAlert(lockoutErrorMessage, "error");
      } else {
        panel.showAlert(
          `Sikertelen bejelentkezés! ${errorMessages[status.code]}`,
          "error"
        );
      }
    },
    async userNotConfirmedExceptionHandler(panel, email) {
      const signup = await resendSignUp(email);
      if (signup.code !== "success") {
        panel.showAlert(
          "Az érvényesítő kód újraküldése nem sikerült!",
          "error"
        );
      }
    },
    notAuthorizedExceptionHandler(panel) {
      panel.showAlert(errorMessages.NotAuthorizedException, "error");
    },
    userNotFoundExceptionHandler(panel) {
      panel.showAlert(errorMessages.UserNotFoundException, "error");
    },
    passwordResetRequiredExceptionHandler() {
      this.$route.push({ name: "newPass" });
    },
  },
  created() {},
};
</script>
